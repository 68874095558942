import React from "react";
import { graphql } from "gatsby";
import _ from "lodash";

import { Layout, ToggleCards } from "../components";

const Approach = ({ data }) => {
  const cardArray = _.sortBy(
    _.get(data, "allContentfulApproachPageCard.nodes"),
    "order"
  );
  const researchArray = _.sortBy(
    _.get(data, "allContentfulApproachPageResearch.nodes"),
    "order"
  );

  return (
    <Layout className="approach" pageData={_.get(data, "contentfulPageData")}>
      <section className="cardsToggleWrapper">
        <div className="cardsToggleContainer">
          <ToggleCards
            iconClass="approachPageIcon"
            data={cardArray}
            type="advanced"
          />
        </div>
      </section>
      <section>
        {researchArray.map((item, index) => {
          return (
            <div key={index} className="researchTable container flex tablet">
              <h2 className="h3 researchDates two columns">
                {item.startYear} - {item.endYear}
              </h2>
              <h3 className="researchTitle four columns">{item.title}</h3>
              <div className="researchDescription six columns">
                <span
                  dangerouslySetInnerHTML={{
                    __html: item.description.childMarkdownRemark.html,
                  }}
                />
                {item.researchStudyTitle ? (
                  <h3 className="p researchStudyTitle">
                    {item.researchStudyTitle}
                  </h3>
                ) : null}
                <div>
                  {(_.get(item, "researchStudies") || []).map(
                    (study, index) => {
                      return (
                        <ToggleCards
                          key={index}
                          iconClass="approachPageIcon"
                          type="basic"
                          data={study}
                        />
                      );
                    }
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </section>
    </Layout>
  );
};

export default Approach;

export const query = graphql`
  query approachQuery {
    contentfulPageData(title: { eq: "Approach" }) {
      title
      description
      image {
        file {
          url
        }
      }
      header
    }
    allContentfulApproachPageResearch {
      nodes {
        startYear
        endYear
        order
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        researchStudies {
          title
          description {
            childMarkdownRemark {
              html
            }
          }
          order
        }
        researchStudyTitle
      }
    }
    allContentfulApproachPageCard {
      nodes {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        order
        icon {
          file {
            url
          }
          title
        }
      }
    }
  }
`;
